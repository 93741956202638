import { throttle } from 'lodash'
import { createContext, FC, useContext, useEffect, useReducer } from 'react'
import { useSlideMapViewSelector } from 'viewer/container'

import { useViewerIdSlideState, useViewerPageProvided } from './ViewerPageProvider'

/**
 * Тротлинг изменения параметров viewCenter, viewZoom, viewRotation
 *
 * Эти значения сразу видно на ui. Оптимально поставлено - 50мс
 * */
const VIEW_STATE_CHANGE_THROTTLE_TIME_MS = 50

type TMapViewInfoContext = {
  viewState: IMapViewState
  setViewState: (viewState: IMapViewAction) => void
}

export const mapViewInitialState: IMapViewState = {
  center: [0, 0],
  rotation: 0,
  zoom: 1,
}

const MapViewInfoContext = createContext<TMapViewInfoContext>({
  setViewState: () => {},
  viewState: mapViewInitialState,
})

export const useMapViewInfoContext = () => useContext(MapViewInfoContext)

const MapViewInfoProvider: FC = ({ children }) => {
  const activeViewerId = useViewerPageProvided().activeViewerId
  const slideId = useViewerIdSlideState(activeViewerId).slideId

  const { viewCenter, viewRotation, viewZoom } = useSlideMapViewSelector({ slideId, viewerId: activeViewerId })
  const { center, rotation, zoom } = mapViewInitialState
  const [viewState, dispatchViewState] = useReducer(mapViewReducer, {
    center: viewCenter ?? center,
    rotation: viewRotation ?? rotation,
    zoom: viewZoom ?? zoom,
  })

  useEffect(() => {
    if (viewCenter !== undefined && viewRotation !== undefined && viewZoom !== undefined) {
      const reduxViewState: IMapViewState = {
        center: viewCenter,
        rotation: viewRotation,
        zoom: viewZoom,
      }

      dispatchViewState({ payload: reduxViewState, type: 'batchUpdate' })
    }
  }, [slideId])

  const setViewState = throttle((viewState: IMapViewAction) => {
    dispatchViewState(viewState)
  }, VIEW_STATE_CHANGE_THROTTLE_TIME_MS)

  return <MapViewInfoContext.Provider value={{ setViewState, viewState }}>{children}</MapViewInfoContext.Provider>
}

export default MapViewInfoProvider

const mapViewReducer = (state: IMapViewState, action: IMapViewAction) => {
  switch (action.type) {
    case 'zoom':
      return { ...state, zoom: action.payload }
    case 'center':
      return { ...state, center: action.payload }
    case 'rotation':
      return { ...state, rotation: action.payload }
    case 'batchUpdate':
      return action.payload
    default:
      return state
  }
}

// Интерфейс для состояния карты
export interface IMapViewState {
  center: number[]
  rotation: number
  zoom: number
}

type IMapViewAction =
  | { type: 'zoom'; payload: number }
  | { type: 'center'; payload: number[] }
  | { type: 'rotation'; payload: number }
  | { type: 'batchUpdate'; payload: IMapViewState }
